import styled from "styled-components";


export const Container = styled.div`
width: 100%;
height: 490px;
grid-area: Advaice;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
z-index: 10;
margin-bottom: 2%;

.mobile{
display: none;
margin-left: -10px;
}
.aling-left{
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 3%;
  position: relative;
  bottom: 10px;
}
.desk{

}
.titleImg{
    width: 940px;
    display: flex;
    align-items: center;
    z-index: 50;
    position: relative;
    top: 33px;
    h4{
      width: 345px;
      font: normal normal 800 22px/30px Poppins
      
  }
  @media (max-width: 820px) {
    h4{
      width: 300px;
      font: normal normal 800 20px/30px Poppins
    }
  }
}

span{
  z-index: 10;
}
@media (min-width: 820px) {
.aling-left{
    display: block;
  justify-content: none;
  width: auto;
  margin-bottom: 3%;
  }
}
@media (max-width: 820px) {
  height: 350px;
  
  .titleImg{
    top: 10px;
    left: 0;
    background-color: transparent;
    width: auto ;
  }

  .position_wrapper{
    width: 90% !important;
    align-items: center !important;
  }

  .mobile{
  display: block;
}



@media (max-width: 500px) {
  height: 350px;
  
  .titleImg{
    top: 10px;
    left: 0;
    background-color: transparent;
    width: auto ;
  }

  .position_wrapper{
    width: 90% !important;
    align-items: flex-start !important;
  }

  .mobile{
  display: block;
  }
}










.desk{
  display: none;

}
}

@media (max-width: 600px) {
  height: auto;
  .titleImg{
    width: 330px ;
  }
}

.img{
  z-index: 20;

}

.position_wrapper {
  /* margin: 0 0 0 3rem; */
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
`
