import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  margin-top: 1rem;
  height: fit-content;
  align-items: center;
  justify-content: center;
  .divWarraper{
    display: flex ;
    justify-content:center ;
    align-items: center;
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .mobile_wrapper {
    display: flex;
    padding: 1rem;

    @media (max-width: 820px) {
      flex-direction: column;
    }
  }

  @media (max-width: 820px) {
    justify-content: center;
  }
`;

export const WrapperIcon = styled.div`
  display: flex;
`;

export const GraphIcon = styled.div`
  width: 200px;
  height: 150px;
  display: flex;
  align-self: center;
  padding-top: 15px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: transparent url(/icon_01.png) 0% 6% no-repeat;
  p {
    color: #FFFFFF;
    margin: -.5rem 0 0 0;
    letter-spacing: 0.34px;
    line-height: 28px;
    font: normal normal medium 14px/18px Poppins;
  }

  b {
    color: #FFFFFF;
    letter-spacing: 0.34px;
    font: normal normal medium 14px/18px Poppins;
  }

  @media (max-width: 820px) {
          width: 170px;
          background-size: 160px 100px;
          height: 100px;

          p{
            font-size:14px ;
          }
    }
  @media (min-width: 820px) {
    width: 221px;
    background-size: 215px
  }
`;

export const MagnifierIcon = styled(GraphIcon)`
  margin: 0 0 .1rem 0;
  background: transparent url(/icon_02.png) 0% 0% no-repeat padding-box;
  @media (max-width: 820px) {
      background-size: 160px 100px;
}
@media (min-width: 820px) {
    width: 221px;
    background-size: 215px
  }
`;

export const StartIcon = styled(GraphIcon)`
  margin: 0 0 .3rem 0;
  background: transparent url(/icon_03.png) 0% -5% no-repeat padding-box;
  @media (max-width: 820px) {
      background-size: 160px 100px;
}
@media (min-width: 820px) {
    width: 221px;
    background-size: 215px
  }
`;

export const IndicatorIcons = styled(GraphIcon)`
  margin: 0 0 .6rem 0;
  background: transparent url(/icon_04.png) 0% -5% no-repeat padding-box;
  @media (max-width: 820px) {
      background-size: 160px 100px;
}
@media (min-width: 820px) {
    width: 221px;
    background-size: 215px
  }
`;
