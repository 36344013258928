import Router from 'next/router';
import { useIndicators } from '@/hooks';
import { SinteseContext } from '@/context';
import { useMediaQuery } from '@mui/material';
import { canvasSize } from '@/utils/canvasMatches';
import { ArrowGreen, Container, SelectOwer } from './styles';
import React, { useContext, useEffect, useMemo, useState } from 'react';

interface IProps {
  isHome?: boolean;
}

interface SelectProps {
  values: any;
  area: string
  isHome?: boolean;
  small?: boolean;
  margin?: boolean;
  isNavigation?: boolean;
  sintese?:boolean;
  width?: string;
}

interface OptionI {
  code: string;
  title: string;
  pdf?: string;
}

const options = [
  {
    code: 'capital_humano',
    title: 'Capital humano',
  },
  {
    code: 'desenvolvimento_economico',
    title: 'Desenvolvimento econômico',
  },
  {
    code: 'condicoes_de_vida',
    title: 'Condições de vida',
  },

  {
    code: 'desenvolvimento_social',
    title: 'Desenvolvimento social',
  },

  {
    code: 'educacao',
    title: 'Educação',
  },

  {
    code: 'infraestrutura',
    title: 'Infraestrutura',
  },

  {
    code: 'institucional',
    title: 'Institucional',
  },

  {
    code: 'juventude',
    title: 'Juventude',
  },

  {
    code: 'saude',
    title: 'Saúde',
  },

  {
    code: 'seguranca',
    title: 'Segurança',
  }
];

export const SelectOur: React.FC<SelectProps> = ({
  area,
  small,
  values,
  isHome,
  margin,
  isNavigation,
  sintese,
  width='80',
}) => {

  const { handleCommitedArea, handleCommitedIndicator, areaCommitedItem } = useIndicators();
  const isMobile = useMediaQuery(canvasSize);
  const [state1, setState1] = useState(false);
  const [selectItem, setSelectItem] = useState(area);
  const { setState, state} = useContext(SinteseContext);
  const [selectItemCrontole, setSelectItemCrontole] = useState(area);

  const onClickValue = (code: string, title: string) => {
    if (area === "Indicador") {
      handleSelectIndicator(code);
    } else {
      handleCommitedArea(code);
    }
    setSelectItem(title);
    setSelectItemCrontole(title);

    if (isMobile) {
      setState1(false);
    }

    if(sintese){
      setState(code);
      Router.push('/sintese');
    }
  };

  const handleSelectIndicator = (code: any) => {
    if (isHome) {
      handleCommitedIndicator(code);
      Router.push('/indicadores');
    } else {
      handleCommitedIndicator(code);

    };
  };

  const classes = useMemo(() => {
    if (state1) {
      return 'body show';
    }

    return 'body hidden';
  }, [state1]);

  useEffect(() => {
    if (area === "Indicador") {
      setSelectItem(area);
    }
  }, [areaCommitedItem]);

  return (
    <SelectOwer
      w={width}
      small={small}
      isMobile={isMobile}
      margin={margin}
      isNavigation={isNavigation}
      onClick={() => {
        if (isMobile) {
          setState1(!state1);
        }
      }}
      onMouseLeave={() => {
        if (!isMobile) {
          setState1(false);
        }
      }}
    >
      {values[0]?.pdf &&
        <>
          <div className='select' onMouseOver={() => {
            if (!isMobile) {
              setState1(true);
            }
          }} >
            <p>{selectItem}</p>
            <ArrowGreen className="spinner" />
          </div>
          <div className={classes}>
            {values?.map((opt: OptionI) => (
              <p onClick={() => onClickValue(opt?.code, opt?.title)}>{opt?.title}</p >
            ))}
          </div>
        </>
      }
      {
        !values[0]?.pdf &&
        <>
          <div className={'select'} onMouseOver={() => {
            if (!isMobile) {
              setState1(true);
            }
          }} >
            <div style={{ width: '100%' }} dangerouslySetInnerHTML={{ __html: `<p>${selectItem as unknown as string}</p>` }} >
            </div>
            <ArrowGreen className="spinner" />
          </div>
          <div className={classes}>
            {values?.map((opt: OptionI) => (
              <div key={opt?.code} onClick={() => onClickValue(opt?.code, opt?.title)} dangerouslySetInnerHTML={{ __html: `<p>${opt?.title as unknown as string}</p>` }} >
              </div>
            ))}
          </div>
        </>
      }
    </SelectOwer >
  );
};

export const Filter: React.FC<IProps> = ({
  isHome,
}) => {
  const { indicatorsData } = useIndicators();
  const { setState,state } = useContext(SinteseContext);

  const dataIndicador = useMemo(() => {
    const newArray: any = [];
    if (indicatorsData !== undefined) {
      indicatorsData?.forEach((element: any) => {
        newArray.push({
          code: element?.code,
          title: element?.complete_name?.split("(")[0],
        });
      });
    }
    return newArray;
  }, [indicatorsData, state]);


  return (
    <Container isHome={isHome}>
      <SelectOur margin values={options} area="Área" />
      <SelectOur values={dataIndicador} area="Indicador" isHome={isHome} />
    </Container>
  );
};
