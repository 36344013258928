import styled from "styled-components";
import LogoMira from '../../../../../public/mira.svg';

interface WrapperProps {
  column?: boolean
}
interface CircleProps{
  transform?: boolean
}
export const Scope = styled(LogoMira)`


`
export const Container = styled.div`

  width: 100%;
  height: 225px;
  grid-area: Acordeon;
  background: #EFEFEF 0% 0% no-repeat padding-box;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 820px) {

    display: none;
  }
  .box{
    display: flex;
    width: 940px;
    justify-content: flex-start;
  }
  .mt-15{
    /* margin-top: 15px; */
  }

  .trasnform{
    width: 12px;
  height: 12px;
  background: #1A8271 0% 0% no-repeat padding-box;
  opacity: 1;
  }
`


export const Wrapper = styled.div<WrapperProps>`
  width: 387px;
  display: flex;
  margin-top: 15px;
  flex-direction: ${props => props.column ? 'column': 'row'};
  h1{
    width: 254px;
    height: 94px;
    /* UI Properties */
    text-align: left;
    font: normal normal 800 23px/30px Poppins;
    letter-spacing: 0px;
    color: #000000;
    margin-left: 31px;
  }

  h3{
    width: 412px;
  height: 46px;
  text-align: left;
  font: normal normal 800 16px/20px Poppins;
  letter-spacing: 0.38px;
  color: #1A8271;
  }


  p{
    width: 479px;
    height: 46px;
    text-align: left;
    font: normal normal medium 16px/20px Poppins !important;
    letter-spacing: 0.38px;
    color: #000000;
  }
`

export const Circle = styled.div<CircleProps>`
  width: 9px;
  height: 9px;
  background:   ${props => props.transform ? '#1A8271' : '#1A827180'}  0% 0% no-repeat padding-box;
  opacity: 1;
  margin-right: 5px;
  margin-top: 15px;
  cursor: pointer;
  border-radius: 100%;

  transform: ${props => props.transform ? 'scale(1.3)' : 'none'} ;

  &:hover{
  background: #1A8271 0% 0% no-repeat padding-box;
    }
`