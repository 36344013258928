import React from 'react';
import { Container, FilterWrapper, Title } from './styles';
import { Filter } from '../../molecules/FilterSection/Filter';
import { InformationIcons } from '../../molecules/FilterSection/informationIcons';

interface IProps {
  isHome?: boolean;
}

export const FilterSection: React.FC<IProps> = ({ isHome=false }) => {
  return (
    <Container>
      <FilterWrapper isHome={isHome}>
        <Title isHome={isHome}>Acesse a visão por indicador</Title>
        <InformationIcons />
        <Filter isHome={isHome} />
      </FilterWrapper>
    </Container>
  );
};
