import styled from 'styled-components'
import Arrow from './assets/arrowWhite.svg'
export const Container = styled.div`

width: 50px;
height: 50px;
background: #1A8271 0% 0% no-repeat padding-box;
opacity: 1;
position: fixed;
right: 0;
display: flex;
justify-content: center;
align-items: center;
top: 90%;
right: 40px;
z-index: 100;
border-radius: 100%;
&:hover{
  background: #1A827180 0% 0% no-repeat padding-box;
  cursor: pointer;
}
`

export const ArrowWhite = styled(Arrow)``