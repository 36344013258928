import styled, { css } from 'styled-components';
import Seta from './assets/setaGreen.svg';
interface SelectOwerProps {
  hid?:boolean;
  small?: boolean;
  margin?: boolean;
  w: string;
  isNavigation?: boolean;
  isMobile?: boolean;
}

interface ContainerProps {
  isHome?: boolean;
}

export const Container = styled.div<ContainerProps>`
  width: 99.5%;
  height: 80px;
  display: flex;
  padding: 0px 20px;
  align-items: center;
  margin: 0px 0.4rem 1rem 0;
  justify-content: space-around;
  background: rgba(256, 256, 256, .3);

  select {
    color: black !important;
  }

  select option:checked {
    box-shadow: 0 0 150px red inset !important;
  }

  @media (max-width: 820px) {
    width: 100%;
    background: none;
    height: fit-content;
    flex-direction: column;
    padding: ${props => props.isHome ? ' 0px 20px' : '0px 0px'}  ;
    align-self: ${props => props.isHome ? 'center' : 'flex-start'}  ;
  }

  @media (max-width: 320px) {
    width: 90%;
    background: none;
  }
`;

export const SelectArea = styled.select`
  width: 100%;
  height: 40px;
  border: none;
  padding: .5rem;
  font-size: 14px;
  margin: 0 .4rem 0 1.2rem;
  background-position-x: 98%;
  background-position-y: 50%;
  appearance: none !important;
  background-repeat: no-repeat;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");

  option:hover,
  option:focus,
  option:checked {
    font-weight: bold ;
    color: black ;
  }

  option: {
    color: black !important;
  }

  option{
    &:hover{
    background-color: transparent
    }
  }

  @media (max-width: 820px) {
    margin: 0 0 .5rem 0;
  }
`;

export const SelectOwer = styled.div<SelectOwerProps>`
  width: ${({ isMobile, w }) => isMobile ? '100' : w}%;
  height: 40px;
  border: none;
  background-color: white;
  z-index: 1;
  position: relative;
  margin-right: ${props => props?.margin ? '20px' : ''};

  @media (max-width: 820px) {
    margin:  ${props => props.small ? '12px 12px 12px 0' : '8px'};
  }

  .body p {
    margin-bottom:10px;

    &:hover{
      font-weight: 900;
      cursor: pointer;
    }
  }

  .hidden{ display: none; }
  .show{ display: flex; }

  p {
    opacity: 1;
    font-size: 14px;
    font-weight: 500;
    color: black !important;
    letter-spacing: 0.35px  !important;
  }

  .select{
    width: 100%;
    height: 40px;
    display: flex;
    padding: .5rem;
    align-items: center;
    justify-content: space-between;

    &:hover { cursor: pointer; }

    p {
      max-width: 90%;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }

  .body {
    width:auto;
    padding: 8px;
    height: auto;
    min-width: 100%;
    z-index: 9999999;
    overflow-y: auto;
    max-height: 230px;
    align-items: start;
    position: relative;
    flex-direction: column;
    background-color: white;
    justify-content: flex-start;
    border: 1px solid var(--light-gray);
  }

  &:hover {
    .spinner{ transform: rotate(180deg); }
  }

`;

export const ArrowGreen = styled(Seta)``;

export const SelectIndicator = styled.select`
  width: 100%;
  height: 40px;
  border: none;
  padding: .5rem;
  font-size: 14px;
  margin: 0 1.2rem 0 0;

  background-position-x: 98%;
  background-position-y: 50%;
  appearance: none !important;
  background-repeat: no-repeat;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");

  option:hover,
  option:focus,
  option:checked {
    font-weight: bold;
  }
  option: {
    color: black !important;
  }

  option{
    &:hover{
    background-color: transparent
    }
  }
  @media (max-width: 820px) {
    margin: .5rem 0 0 0;
  }
`;
