import React from 'react';
import { Container } from './styles';
import Image from 'next/legacy/image';
import { useNavigator } from '@/hooks';
import { ButtonGrenn } from '@/components/ranking/atoms/Footer/style';


export const Advaice: React.FC = () => {
  const { handleChangePathname } = useNavigator();

  const handleClick = () => {
    handleChangePathname('/ranking');
  };
  return (
    <Container>
      <div className='position_wrapper'>
        <div className='titleImg'>
          <h4>Veja a posição do seu estado no ranking</h4>
        </div>
        <div>
          <div className='mobile'>
          <Image height={172} width={345} src="/lupa.png" />

          </div>
          <div className='desk'>
          <Image className='img' height={345} width={941} src="/lupa_final.png" />

          </div>
        </div>
        <div className='aling-left'>
        <ButtonGrenn onClick={() => handleClick()}>
          Ver ranking
        </ButtonGrenn>
        </div>
      </div>
    </Container>
  );
};
