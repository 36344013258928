import styled from "styled-components";
interface ContainerProps {
  enable: boolean
}
export const Container = styled.div<ContainerProps>`
display: ${props => props.enable ? 'flex' : 'none'} ;
width: 100%;
height: 80px;
background-color:#0E2631 ;
right: 0;
bottom: 0;
z-index:99999 ;
position:fixed ;
justify-content: center;
.container{
  display: flex ;
  justify-content:space-between ;
  align-items:center ;
  width: 80% ;

}
p{
  color:white ;
  font-size:13px !important;
}

b{
  color:white ;
  text-decoration: underline ;

  &:hover{
    color:#198370;
    cursor: pointer;
  }
}

@media (max-width: 820px) {
    flex-direction:column ;
    height: auto;
    padding: 5%;
    justify-content: flex-start ;
    align-items:flex-start ;
    .container{
      flex-direction:column ;
    height: auto;
    justify-content: flex-start ;
    align-items:flex-start ;
    }
   }
`