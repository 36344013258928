import React from 'react';
import { Container } from './styles';
import { useIndicators } from '@/hooks';
import { BgHome } from '../atoms/BgHome';
import {Advaice} from '../molecules/Advaice';
import {HelpButon} from '../atoms/HelpButon';
import { Header } from '../organisms/Header';
import {Acordeon} from '../molecules/Acordeon';
import { Sintesi } from '../molecules/sinstesi';
import {Footer} from '@/components/ranking/atoms/Footer';
import AcceptCookies from '@/global/components/AcceptCookies';
import { FilterSection } from '@/components/viewIndicator/organisms/FilterSection';


export const Home: React.FC = () => {
  const { indicatorCommitedItem } = useIndicators();

  return (
    <>
      <Container>
        <Header />
        <BgHome />
        <Acordeon />
        <Advaice />
        <Sintesi />
        <FilterSection isHome={true} />
        <Footer
          area='footer'
          isHome={true}
          isNavigation={true}
          displacement={!!indicatorCommitedItem}
        />
        <HelpButon />
        <AcceptCookies />
      </Container>
    </>
  );
};
