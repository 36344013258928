import React from 'react';
import { estados } from '@/utils/estados';
import { useMediaQuery } from '@mui/material';
import { canvasSize } from '@/utils/canvasMatches';
import SintesePath from 'public/sintese_bg_desktop.png';
import SinteseMobilePath from 'public/sintese_bg_mobile.png';
import { SelectOur } from '@/components/viewIndicator/molecules/FilterSection/Filter';
import { BoxFirstLayer, FirstLayer, MobileInputContainer, WrapperInput, Clipt } from './styles';

export const Sintesi = () => {
  const isMobile = useMediaQuery(canvasSize);

  const StatesSelect = React.useMemo(() => {
    const newArray: any = [];
    estados.forEach((element: any) => {
      newArray.push({
        code: element.sigla,
        title: element.sigla,
        pdf: element?.pdf
      });
    });
    return newArray;
  }, [estados]);

  return (
    <BoxFirstLayer isMobile={isMobile}>
      {!isMobile ? (
        <div style={{ width: '70%' }}>
          <p className='p'>Conheça os desafios do seu estado</p>
          <Clipt>
            <img src={SintesePath.src} alt='background_section' />
          </Clipt>
          <FirstLayer>
            <WrapperInput>
              <SelectOur
                small
                sintese
                area="Estado"
                width='100'
                values={StatesSelect}
                isNavigation={true}
              />
            </WrapperInput>
          </FirstLayer>
        </div>
      ) : (
        <>
          <Clipt>
          <div className='wrapper'>
            <p className='p'>Conheça os desafios do seu estado</p>
          </div>
            <img src={SinteseMobilePath.src} alt='background_section' />
          <MobileInputContainer>
            <SelectOur
              small
              sintese
              area="Estado"
              values={StatesSelect}
              isNavigation={true}
            />
          </MobileInputContainer>
          </Clipt>
        </>
      )}
    </BoxFirstLayer>
  );
};
