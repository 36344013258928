import styled from 'styled-components';

interface IProps {
  existArea?: boolean;
}

interface HomeProps {
  isHome?: boolean;
}

export const Container = styled.div<IProps>`
  display: flex;
  padding: 0 0 4rem 0;
  justify-content: center;
  grid-area: filter_section;
  background-size: cover !important;
  clip-path: polygon(0% 15.5%, 20.1% 15.12%, 29.17% 0%, 77.75% 0%, 100% 0%, 100% 100%, 0% 100%);
  height: fit-content;
  background: transparent url(indicator_view/img_criancas.png) 0% 0% no-repeat padding-box;

  @media (max-width: 820px) {
    height: fit-content;
    clip-path: none;
  }
`;

export const FilterWrapper = styled.div<HomeProps>`
  width: 930px;
  display: flex;

  margin-top: ${props => props.isHome ? '6rem' : '3rem'};
  text-align: left;

  @media (max-width: 820px) {
    margin-top: ${props => props.isHome ? '16rem' : '1rem'};
  }

  height: fit-content;
  align-items: center;
  flex-direction: column;

  @media (max-width: 820px) {
    width: auto;
    margin-top: 1rem;
  }
`;

export const Title = styled.p<HomeProps>`
  width: 100%;
  color: #FFFFFF;

  margin-bottom: -7px;
  letter-spacing: 0.38px;

  text-align: ${props => props.isHome ? 'center' : ''};

  font: normal normal 800 22px/30px Poppins;

  @media (max-width: 820px) {
    width:95% ;
    margin-left: 5%;
    text-align: left;

    margin-bottom: -7px;
    font: normal normal 800 20px/30px Poppins;

  }
`;
