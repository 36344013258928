import React from 'react';

import { ArrowWhite, Container } from './style';

export const HelpButon: React.FC = () => {
  return (
    <Container onClick={() => {
      window.scrollTo({top: 0, behavior: 'smooth'});
    }}>
      <ArrowWhite />
    </Container>
  )
}
