import React, { useEffect, useState } from 'react';
import { Circle, Container, Scope, Wrapper } from './style';

export const Acordeon: React.FC = () => {
  const messages = [
    {
      title: "Disseminar a gestão baseada em evidências para ganhar foco das escolhas.",
      subtitle: "Reduzir dispersão de recursos e ineficiências das políticas."
    },
    {
      title: "Estimular a construção de estratégias de longo prazo.",
      subtitle: "Evitar governos orientados à “gestão das urgências.”"
    },
    {
      title: "Melhorar a execução.",
      subtitle: "Identificar boas práticas e buscar soluções que possam ser compartilhadas."
    },
    {
      title: "Ampliar a transparência.",
      subtitle: "Identificar e disseminar os desempenhos dos estados e os resultados das políticas."
    }
  ]
  const [message, setMessage] = useState(messages[0]);
  const [count, setCount] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      if (count === (messages.length - 1)) {
        setCount(0)
      } else {
        if (count >= 0) {
          setCount(count + 1);
        }
      }
      setMessage(messages[count])

    }, 5000)
    return () => clearInterval(interval);
  })

  useEffect(() => {
    setMessage(messages[count])
  }, [count])

  return (
    <Container>
      <div className='box'>
        <Wrapper style={{ marginRight: '65px' }}>
          <Scope />
          <h1>Objetivos do estudo Desafios da Gestão Estadual</h1>
        </Wrapper>
        <Wrapper column className='mt-15'>
          {/* <h3>{message?.title}</h3> */}
    
          <p><b style={{fontWeight: '900'}}>{message?.title}</b> {message.subtitle}</p>
          <Wrapper style={{position:"relative", top:"20px"}}>
            {
              messages.map((msg, i) => <Circle transform={i === count} key={msg.title} onClick={() => {
                setCount(i);
              }} />)
            }
          </Wrapper>
        </Wrapper>
      </div>
    </Container>
  )
}
