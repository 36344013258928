import Image from "next/legacy/image"
import { BlackLayer, ContainerHome } from "./style"

export const BgHome: React.FC = () => {
  return <ContainerHome>
    <BlackLayer>
      <div className="logo">
        <Image width={450} height={110} src="/logo_dge_2x.png" />
      </div>
       <div className="logoMobile">
        <Image width={260} height={90} src="/logo_dge_2x.png" />
      </div>
    </BlackLayer>
  </ContainerHome>
}
