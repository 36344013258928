import { ButtonGrenn } from '@/components/ranking/atoms/Footer/style'
import React, { useEffect, useState } from 'react'
import { Container } from './style'
import Cookies from 'js-cookie';

export default function AcceptCookies() {
  const [enable, setEnable] = useState(false)

  const getAproov = async () => {
    const userAcceptCookie = Cookies.get("accept_cookie");
    if (userAcceptCookie) {
      return setEnable(false)
    }
    return setEnable(true )
  }

  useEffect(()=>{
    getAproov()
  },[])
  const handleClick = () => {
    setEnable(!enable)
    Cookies.set("accept_cookie", JSON.stringify(true))
  }

  return (
    <Container enable={enable}>
      <div className='container'>
        <p> Esse site utiliza cookies para garantir uma melhor experiência de navegação. Ao continuar, você está de acordo com esta prática.
          <b style={{ margin: '0 0 0 .5rem' }} >
          <a
            target="_blank"
            rel="noopener noreferrer"
            href='/privacy/Politica_de_Privacidade.pdf'
          >
            Saiba mais
          </a>
          </b>
        </p>
        <ButtonGrenn style={{ marginRight: "3%" }} onClick={() => handleClick()}>
          Aceitar cookies
        </ButtonGrenn>
      </div>
    </Container>
  )
}
