import React from 'react';
import Link from 'next/link';
import { Container } from './styles';
import { Tabs } from '@/components/ranking/molecules/Header/Tabs';
import { FormsHeader } from '@/components/ranking/molecules/Header/Forms';
import { Hamburguer } from '@/components/ranking/molecules/Header/Hamburguer';

export const Header: React.FC = () => {
  return (
    <Container id='header'>
      <Link  href="/">
        <div className='boxLogo'>
          <div className='hidden'>
          </div>
        </div>
      </Link>
      <Hamburguer />
      <Tabs />
      <FormsHeader />
    </Container >
  );
};
