import {
  Content,
  Container,
  GraphIcon,
  StartIcon,
  WrapperIcon,
  MagnifierIcon,
  IndicatorIcons,
} from './styles';
import React from 'react';
import { useMediaQuery } from '@mui/material';
import { canvasSize } from '@/utils/canvasMatches';

export const InformationIcons: React.FC = () => {
  const isMobile = useMediaQuery(canvasSize);

  return (
    <Container>
      <Content>
        {isMobile && (
          <div className='mobile_wrapper'>
            <div className='divWarraper'>
              <WrapperIcon>
                <GraphIcon>
                  <p>Evolução</p>
                  <p>temporal</p>
                </GraphIcon>
              </WrapperIcon>
              <WrapperIcon>
                <MagnifierIcon>
                  <p>Analise e</p>
                  <p>destaques</p>
                </MagnifierIcon>
              </WrapperIcon>
            </div>
            <div className='divWarraper'>
              <WrapperIcon>
              <StartIcon>
                <p>Melhores, piores e</p>
                <p>maiores variações</p>
              </StartIcon>
              </WrapperIcon>
              <WrapperIcon>
              <IndicatorIcons>
              <b>31</b>
              <p>Indicadores</p>
              </IndicatorIcons>
              </WrapperIcon>
            </div>
          </div>
        )}
        {!isMobile && (
          <>
            <WrapperIcon>
              <GraphIcon>
                <p>Evolução</p>
                <p>temporal</p>
              </GraphIcon>
            </WrapperIcon>
            <WrapperIcon>
              <MagnifierIcon>
                <p>Analise e</p>
                <p>destaques</p>
              </MagnifierIcon>
            </WrapperIcon>
            <WrapperIcon>
              <StartIcon>
                <p>Melhores, piores e</p>
                <p>maiores variações</p>
              </StartIcon>
            </WrapperIcon>
            <WrapperIcon>
              <IndicatorIcons>
                <b>31</b>
                <p>Indicadores</p>
              </IndicatorIcons>
            </WrapperIcon>
          </>
        )}
      </Content>
    </Container>
  );
};
