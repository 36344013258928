import styled from 'styled-components';

interface FirstLayerProps {
  isIndicator?: boolean;
}
interface BoxFirstLayerProps {
  isMobile: boolean;
}

export const BoxFirstLayer = styled.div<BoxFirstLayerProps>`
  width: 100%;
  display: flex;
  background: #F3F3F2;
  /* overflow: visible; */
  position: relative;

  grid-area: sintese;
  align-items: center;

  flex-direction: column;
  justify-content: flex-start;

  @media (max-width: 820px) {
    overflow: visible;
  }

  height: ${({ isMobile }) => isMobile ? 'fit-content' : '800px'};

  &::before {
    content: '';
    top: -35%;
    left: 0;

    width: 100%;
    z-index: -1;

    opacity: 0.5;
    height: 100%;

    position: absolute;
    background-size: cover;
    background-position: center ;
    background-image: url(background_arrow_filter.png);
  }

  .p {
    width: 70%;
    color: black;

    margin: 2rem 0 0 0;
    font: normal normal 800 22px/30px Poppins;
  }

  @media (max-width: 820px) {
    clip-path: none;
    flex-direction: column;

    .wrapper {
      width: 100%;
    }

    .p {
      width: 80%;
      color: black;
      margin: 1rem 0 1rem .5rem;
      font: normal normal 600 19px/24px Poppins;
    }
  }

  @media (min-width: 2559px) {
    height: 850px;
  }
`;

export const Clipt = styled.div`
  width: 100%;

  img {
    width: 100%;
  }

  @media (max-width: 820px) {
    width: 100%;
    padding: 0 1rem 0 1rem;
    /* position: relative; */
    background-size: cover;
    background-position: center ;
    background-image: url(background_arrow_filter.png);

    img {
      position: initial;
    }
  }
`;

export const FirstLayer = styled.div<FirstLayerProps>`
  width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: 820px) {
    margin-top: 0;
    clip-path: none;
  }
`;

export const WrapperInput = styled.div`
  width: 50%;
  margin: 1rem;
  padding: 1rem;
  background-color: rgba(32,160,136, .2);

  @media (max-width: 820px) {
    align-items: center;
  }
`;

export const MobileInputContainer = styled.div`
  padding: 0 .5rem 1rem .5rem;
`;
