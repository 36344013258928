import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 0vh auto auto auto 60vh auto 0vh 0vh minmax(0vh,100%);
  grid-template-areas: "header header" "ContainerHome ContainerHome"
  "Acordeon Acordeon" "Advaice Advaice" "sintese sintese" "filter_section filter_section" "footer footer";

  @media (max-width: 820px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto  auto auto auto auto minmax(0vh,100%);
    grid-template-areas: "header" "ContainerHome" "Advaice" "sintese sintese" "filter_section" "footer";
 }
 @media (min-width: 1440px) {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 0vh auto auto auto 60vh auto 0vh 0vh minmax(0vh,100%);
  grid-template-areas: "header header" "ContainerHome ContainerHome"
  "Acordeon Acordeon" "Advaice Advaice" "sintese sintese" "filter_section filter_section" "footer footer";
 }

 @media (min-width: 2559px) {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 0vh auto auto auto 60vh 445px  0vh 0vh minmax(0vh,100%);
  grid-template-areas: "header header" "ContainerHome ContainerHome"
  "Acordeon Acordeon" "Advaice Advaice" "sintese sintese" "filter_section filter_section" "footer footer";
 }
`;
