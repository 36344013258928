import styled from 'styled-components';

export const Container = styled.header`
  width: 100%;
  display: flex;
  padding: 1rem;
  grid-area: header;
  position: absolute;
  align-items: center;
  background: transparent;
  justify-content: space-around;
  max-height: 79px;

  .hidden {
    margin-left: 5%;
  }
  .hidden2 {
  }

  .boxLogo {
    width: 138px;
    height: 48px;
  }

  @media (max-width: 820px) {
    width: max(100%, 378px);
    justify-content: space-between;
    padding-left: 5% ;
    .boxLogo{
      width: auto;
    }
  }

  @media (min-width: 820px) {
    .hidden{
      display: none;
    }
    .hidden2{
      display: none;
    }
  }
`;
