import styled from 'styled-components';


export const ContainerHome = styled.div`
  width: 100%;
  height: 70vh;
  max-height: 450px;
  min-height: 450px;
  grid-area: ContainerHome;
  background-image: url("bgHome.png");
  background-color: black;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 820px) {
    max-height: 350px;
    min-height: 350px;
  }
`;

export const BlackLayer = styled.div`
  background: transparent linear-gradient(180deg, #0E2E38 0%, #0E2E38CC 100%) 0% 0% no-repeat padding-box;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .logo{
    margin-top: 15px;
  }
  .logoMobile{
    margin-top: 20px;
  }
  @media (max-width: 820px) {
    .logo{
      display: none;
    }
  }
  @media (min-width: 820px) {
    .logoMobile{
      display: none;
    }
  }

`

